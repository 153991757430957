import { Injectable } from '@angular/core';
import { DateTime } from 'luxon';
import { UserPermission } from '../permissions/user-permissions';
import { User } from '../user';
import { LegacyUser } from './legacy-user';

@Injectable({ providedIn: 'root' })
export class LegacyUserAdapterService {
  format(user: LegacyUser): User {
    return {
      id: user.id,
      type: user.is_lawyer ? 'Lawyer' : 'Non-lawyer',
      fullname: user.nom,
      email: user.email,
      phone: user.mobile,
      newsletterConfig: {
        days: Object.values(user.newsletter_config.days)
          .filter((value) => value !== '')
          .map(Number),
        newsCategoryIds: Object.values(
          user.newsletter_config.topics_selected,
        ).map(Number),
        hour: Number(user.newsletter_config.hour),
      },
      verified: Boolean(user.is_verified),
      createdAt: DateTime.fromSeconds(user.ts_creation, {
        zone: 'Europe/Paris',
      }).toISO(),
      expireAt:
        user.expiration &&
        DateTime.fromSQL(user.expiration, { zone: 'Europe/Paris' }).toISO(),
      permissions: this.getLegacyUserPermissions(user),
      category: user.profile,
      isLegacy: user.isLegacy ?? true,
      hash: user.hash,
      clients: user.clients,
      occupation: user.occupation,
    };
  }

  private getLegacyUserPermissions(user: LegacyUser): UserPermission[] {
    if (user.is_proxy_access) {
      return [];
    }
    return [
      'access-account',
      'access-detect',
      'access-documents',
      'access-search-history',
      'access-alerts',
      'access-newsletter-configuration',
    ];
  }
}
