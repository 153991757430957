import { Injectable, Signal } from '@angular/core';
import { Observable } from 'rxjs';
import { LegacyAuthService } from './legacy/legacy-auth.service';
import { UserPermission } from './permissions/user-permissions';
import { User } from './user';

export interface SignUpLawyerDetailsData {
  type: 'lawyer';
  bar: string;
}

export type SignUpDetailsData = SignUpLawyerDetailsData | null;

export interface SignUpData {
  token: string;
  firstname: string;
  lastname: string;
  job: string;
  details: SignUpDetailsData;
  password: string;
  termsAndConditionsOfSaleAndUseAccepted: boolean;
  newsletterAccepted: boolean;
  receivePromotionalAndCommercialOffersAccepted: boolean;
}

@Injectable({
  providedIn: 'root',
  useExisting: LegacyAuthService,
})
export abstract class AuthService {
  abstract readonly isAuthenticated: Signal<boolean>;
  abstract readonly user: Signal<User | null | undefined>;

  abstract getCurrentUser(): Observable<User | null>;
  abstract currentUserHasPermission(
    permission: UserPermission,
  ): Signal<boolean>;
  abstract currentUserHasSomePermissions(
    permissions: UserPermission[],
  ): Signal<boolean>;
  abstract checkAccount(code: string): Observable<void>;
  abstract signIn(
    email: string,
    password: string,
    cloudflareTurnstileToken: string,
  ): Observable<string>;
  abstract signInWithToken(token: string): void;
  abstract signInByIPAddress(): Observable<string | null>;
  abstract signUp(data: SignUpData): Observable<void>;
  abstract askForNewPassword(email: string): Observable<void>;
  abstract logout(): void;
}
